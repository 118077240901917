import React, { useContext, useState, useEffect } from 'react';
import context from '../../context';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { Container } from 'react-bootstrap';
import "./hero.css";
import { CheckCircleFilled } from '@ant-design/icons';

import { Section, Button } from '../../styled-components';
import FormProperty from '../forms/properties'
import FormCode from '../forms/code';
import { Agro } from '../../icons';
import Link from '../link';

import { Input } from '../inputs';

const MainCont = styled(Section)`
  /*background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url("${props => props.src}");
  background-attachment: fixed;
  background-position: top;
  background-size: cover;*/
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`
const VideoOverLay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;  
  top: 0;
  left: 0;
  background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5));
  //z-index: 100;
`
const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
  filter: grayscale(1.5);
`
const TitleCont = styled.div`
  position: relative;
  z-index: 5;
  @media(min-width: 768px){
    max-width: 50%;
  }  
`
const Title = styled.h1`
  //text-align: center;
  color: #fff;
  font-size: 2rem;
`
const SubTitle = styled.p`
  font-size: 1rem;
`

const InputCont = styled.div`
  display: flex;
  flex-direction: column;
  @media(min-width: 768px){
    max-width: 80%;
    flex-direction: row;
  }  
`
const InputCustom = styled(Input)`
  border-radius: 0px;
`
const ButtonCustom = styled(Button)`
  border-radius: 0px;
  padding: 0 2rem;
`

const SearchOptionCont = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  @media(min-width: 768px){

  }
`
const SearchOption = styled.button`
  background: transparent;
  width: 100%;
  padding: 1rem 0;
  color: ${props => props.active ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, .6)" };
  position: relative;
  border: none;
  font-weight: bold;
  display: flex;
  justify-content: center;
  transition: 250ms ease;
  @media(min-width: 768px){
    width: 25%;
  }
`
const ButtonLine = styled.div`
  position: absolute;
  bottom: .5rem;
  //left: 0;
  transition: 250ms ease;
  width: ${props => props.active ? "50%" : "0"};
  height: 3px;
  background-color: ${props => props.active ? props.theme.primaryColor : "transparent"};
`
const DownLink = styled.a`
  text-decoration: none;
  color: #fff !important;
  background-color: ${props => props.theme.primaryColor};
  transition: 250ms ease;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover{
    filter: brightness(1.1);
  }
`
const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`
const Item = styled.li`
  display: flex;
  align-items: center;
  min-height: 2rem;
`
const Icon = styled.span`
  color: ${props => props.theme.primaryColor};
  margin-right: .5rem;
  font-size: 18px;
  line-height: 0;
`
const ButtonCont = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 5;
  margin-top: 2rem;
`

export default ()=> {
  const state = useContext(context);
  const [byCode, setByCode] = useState(false);
  useEffect(()=>{
    const tl = gsap.timeline();
    tl.from("#title", { opacity: 0, y: 10, duration: 1.5, ease:"back.out(1.7)" })
      .from("#search", { opacity: 0, y: 10, duration: 1, ease:"back.out(1.7)" }, "-=1")
      .from("#formSearch", { opacity: 0, y: 10, duration: 1.5, ease:"back.out(1.7)" }, "-=.5")
      .from("#downButton", { opacity: 0, y: 10, duration: 1.5, ease:"back.out(1.7)" }, "-=.5")
      .from("#downButton", { y: 5, repeat: -1, duration: 1.5, yoyo: true });
    
    return ()=> {
      tl.kill();
    }
  },[]);

  return(
    <MainCont 
      src={state.home.hero.background} first height="100vh"
    >
      <Video
        src={require('../../videos/agents.mp4')}
        autoPlay
        loop
        muted
        playsinline
        poster={require('../../videos/agents.webp')}
      />      
      <VideoOverLay />
      <Container>
        <TitleCont>
          <Title>
            Únete a la red de agentes que esta revolucionando el sector inmobiliario.
          </Title> 
          <List>
            <Item>
              <Icon>
                <CheckCircleFilled  twoToneColor="#C53A31" />
              </Icon>
              Publicaciones profesionales sin costo
            </Item>
            <Item>
              <Icon>
                <CheckCircleFilled  twoToneColor="#C53A31" />                
              </Icon>
              Prioriza tu relación con clientes
            </Item>
            <Item>
              <Icon>
                <CheckCircleFilled  twoToneColor="#C53A31" />
              </Icon>
              Evaluación comercial online
            </Item>
            <Item>
              <Icon>
                <CheckCircleFilled  twoToneColor="#C53A31" />
              </Icon>
              Te acompañamos en todo el proceso
            </Item>
            <Item>
              <Icon>
                <CheckCircleFilled  twoToneColor="#C53A31" />
              </Icon>
              Digitaliza tu estilo de trabajo
            </Item>
            <Item>
              <Icon>
                <CheckCircleFilled  twoToneColor="#C53A31" />
              </Icon>
              Conviertete en un experto inmobiliario
            </Item> 
            <Item>
              <Icon>
                <CheckCircleFilled  twoToneColor="#C53A31" />
              </Icon>
              Más productos, más comisiones.
            </Item>                                                                        
          </List>
        </TitleCont>
        {/* <SearchOptionCont id="search">
          <SearchOption active={!byCode} onClick={()=> setByCode(false)}>
            Buscar propiedad
            <ButtonLine active={!byCode} />
          </SearchOption>
          <SearchOption active={byCode} onClick={()=> setByCode(true)}>
            Buscar por código
            <ButtonLine active={byCode} />
          </SearchOption>          
        </SearchOptionCont>
        {
          byCode          
          ?<FormCode />
          :<FormProperty id="formSearch" />
        } */}
        <ButtonCont>
          <Link to="/contact-agents">
            <ButtonCustom primary>
              Saber más
            </ButtonCustom>
          </Link>
        </ButtonCont>
      </Container>
      <div className="shapedividers_com-6707" />
      {/* <DownLink id="downButton" href="#properties">
        <DownOutlined />
      </DownLink> */}
    </MainCont>
  )
}